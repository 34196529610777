export class CapitalizeUtils {
  private static abbreviation(s: string): boolean {
    return /^([A-Z]\.)+$/.test(s);
  }

  private static romanNum(s: string): boolean {
    return /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(s);
  }

  static capitalize(text: string): string {
    const prepos = ['da', 'do', 'das', 'dos', 'a', 'e', 'de'];
    return text
      .split(' ')
      .map((word) => {
        if (
          CapitalizeUtils.abbreviation(word) ||
          CapitalizeUtils.romanNum(word)
        ) {
          return word;
        }
        word = word.toLowerCase();
        if (prepos.includes(word)) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
